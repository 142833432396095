<template>
    <div class="flex flex-col transfer grow">
        <TransferSelector 
            v-model="state.values"
            placeholder="Search categories"
            :options="CATEGORIES"
            :optionsAsValues="false"
        >
        </TransferSelector>
    </div>
</template>
<script>
import TransferSelector from "@/components/inputs/TransferSelector.vue";
import { CATEGORIES } from "@/utils/constants";

export default {
    props: {
        interface: {
            type: Object,
            required: true
        },
        state: {
            type: Object,
            default: {}
        }
    },
    components: {
        TransferSelector
    },
    setup: () => ({
        CATEGORIES
    }),
    mounted() {
        this.state.values = [];
        this.interface.flyout.setHeader('Add Categories');
    }
}
</script>