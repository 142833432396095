<template>
    <div class="flex flex-col grow">
        <InputField
            v-model="search"
            icon="search-2-line"
            :placeholder="placeholder"
            :labelAsPlaceholder="false"
        />
        <slot name="toolbar"></slot>
        <NTransfer
            :options="optionsFiltered"
            :value="selectedOptions"
            @update:value="onUdpdate"
        />
    </div>
</template>
<script>
import { NTransfer } from "naive-ui"
import InputField from "./InputField.vue";

export default {
    props: {
        modelValue: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: "Select options"
        },
        options: {
            type: Array,
            default: []
        },
        placeholder: {
            type: String,
            default: "Select options"
        },
        optionsAsValues: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    components: {
        NTransfer,
        InputField
    },
    data() {
        return {
            value: null,
            search: ''
        }
    },
    computed: {
        optionsFiltered() {
            if (!this.search) return this.options;

            return this.options.filter((option) => option.label.toLowerCase().includes(this.search.toLowerCase()));
        },
        selectedOptions() {
            return this.optionsAsValues
                ? this.modelValue.map(i => i.value)
                : this.modelValue;
        }
    },
    methods: {
        onUdpdate(values) {
            if (this.optionsAsValues) {
                values = values.map(i => this.options.find(o => o.value === i));
            }

            this.$emit('update:modelValue', values);
        }
    }

}
</script>