<template>
    <div class="flex flex-col bg-[#F3F4F6] p-[8px] mt-[10px] border-b-[1px] border-t-[1px] border-b-[#E5E7EB] border-t-[#E5E7EB]">
        <span class="text-[#1F2A37] font-md">Add Region</span>
        <div class="flex flex-wrap gap-[10px]">
            <label v-for="region in Object.keys(REGIONS)" :key="region" @click="setRegionStates(region)">
                <span class="block border-[1px] border-[#D0D5DD] rounded-[6px] px-[6px] py-[3px] bg-[#FFF] font-medium text-[#344054] cursor-pointer select-none flex items-center gap-[4px]" :class="{'active': selectedRegions.includes(region) }">
                    <img width="8" height="8" src="@assets/images/icons/plus-2.svg" alt="">
                    {{ region }}
                </span>
            </label>
        </div>
    </div>
</template>
<script>
import { REGIONS, STATES } from '@/utils/states';

export default {
    props: {
        modelValue: {
            type: Array,
            default: null
        },
    },
    setup: () => ({ REGIONS }),
    data: () => ({
        regions: []
    }),
    computed: {
        selectedRegions() {
            return Object.entries(REGIONS)
                .filter(([_, states]) => Object.keys(states)
                .every(state => this.modelValue.includes(state)))
                .map(([region, _]) => region);
        }
    },
    methods: {
        setRegionStates(region) {
            let newStates = [...this.modelValue];

            this.selectedRegions.includes(region)
                ? newStates = newStates.filter((state) => !Object.keys(REGIONS[region]).includes(state))
                : newStates.push(...Object.keys(STATES.regions([region, ...this.selectedRegions])));
    
            newStates = newStates.filter((o, index, self) => index === self.findIndex((t) => (t === o)));
          
            this.$emit('update:modelValue', newStates);
            this.$emit('onSetRegions', newStates);
        }
    }
}
</script>
<style scoped>
span.active {
    background-color: #B9E6FE;
}
span.active > img {
    transform: rotate(45deg);
}
</style>