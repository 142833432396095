<template>
    <aside 
        class="fixed top-0 right-0 h-screen w-full sm:w-auto sm:min-w-[450px] flex flex-col px-[16px] py-[20px] bg-[#F6F8FA] rounded-l-[16px] transition-transform sm:shadow-md z-[1000]"
        :class="{ 'transform translate-x-full': !isVisible }"
    >
        <span class="text-xs font-medium text-[#868C98]">{{ title }}</span>
        <div class="flex flex-col gap-[16px] overflow-scroll h-full">
            <component v-if="view" :is="view" :interface="store" :params="params" :state="state"></component>
                
            <div class="ml-auto mt-auto flex gap-[16px] pt-[12px]">
                <button @click="onCancel()" class="rounded-[8px] h-[36px] px-[12px]">
                    Cancel
                </button>
                <button @click="onSave()" class="rounded-[8px] bg-[#1570EF] shadow-sm h-[36px] px-[12px] text-[#fff]">
                    Save
                </button>
            </div>
        </div>
    </aside>
</template>
<script>
import FlyoutCategories from './FlyoutCategories.vue';
import FlyoutStates from './FlyoutStates.vue';


export default {
    props: {
        store: {
            type: Object,
            required: true
        }
    },
    components: {
        FlyoutCategories,
        FlyoutStates
    },
    data: () => ({
        isVisible: false,
        view: '',
        title: '',
        params: null,
        state: {}
    }),
    mounted() {
        this.store.flyout = this;
    },
    methods: {
        open() {
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.view = '';
            this.title = '';
            this.state = {};
        },
        setView(viewName, params=null) {
            this.view = viewName;
            this.params = params;
            this.open();
        },
        setHeader(title=null) {
            this.title = title;
        },
        setState(state) {
            this.$nextTick(()=>{
                this.state = state;
            })
        },
        onCancel() {
            this.close();
        },
        onSave() {
            if (this.params.onSave) this.params.onSave(this.state);
            this.close();
        }
    }
}
</script>
<style scoped>
.overflow-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
</style>