<template>
    <div class="flex flex-col">
        <label class="input" :class="{'error': validator.$errors.length }">
            <img v-if="iconUrl" :src="iconUrl" alt="">
            <div class="flex flex-col grow">
                <input
                    ref="input"
                    tabindex="-1"
                    :type="type"
                    :required="required"
                    :value="modelValue"
                    @keyup="onUpdate"
                    @focus="onFocus"
                    @blur="onBlur"
                    :placeholder="labelAsPlaceholder ? null: placeholder"
                >
                <span v-if="labelAsPlaceholder" class="placeholder select-none" :class="{'not-empty': modelValue?.length || typeof(modelValue) == 'number' }">
                    {{ placeholder }}
                </span>
            </div>
        </label>
        <InputErrorHelpTip :validator="validator" />
    </div>
</template>
<script>
import InputErrorHelpTip from "@/components/feedback/InputErrorHelpTip.vue";
import { getIconURL } from '@/utils/assets';

export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        validator: {
            type: Object,
            default: { $errors: [] }
        },
        onFocus: {
            type: Function,
            default: () => {}
        },
        onBlur: {
            type: Function,
            default: () => {}
        },
        labelAsPlaceholder: {
            type: Boolean,
            default: true
        }
    },
    components: { InputErrorHelpTip },
    emits: ['update:modelValue'],
    data() {
        return {
            iconUrl: getIconURL(this.icon)
        }
    },
    methods: {
        async onUpdate(event) {
            let value = event.target.value;
            
            if (this.type == 'number' && typeof value != 'number') {
                value = Number(value);
            }
     
            this.$emit('update:modelValue', value);

            if (this.validator.hasOwnProperty('$validate')) {
                await this.validator.$validate();
            }
        }
    }
}
</script>