import { loadJSON } from '@/utils/load_json';

function AsOptions(array, valueField='id', labelField='name') {
    if (array) {
        const options = array.map(o => {
            return {
                value: o[valueField],
                label: o[labelField]
            }
        })
        
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }
}

export const CATEGORIES = AsOptions(loadJSON('categories'));
export const DISCIPLINES = AsOptions(loadJSON('disciplines'));
