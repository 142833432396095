<template>
    <div class="flex flex-col transfer grow">
        <RegionSelector v-if="Array.isArray(state.values)" v-model="state.values" @onSetRegions="onSetRegions" />
        <TransferSelector 
            v-model="state.values"
            placeholder="Search states"
            :options="STATES.options()"
            :optionsAsValues="false"
        >
        </TransferSelector>
    </div>
</template>
<script>
import RegionSelector from "@/components/inputs/RegionSelector.vue";
import TransferSelector from "@/components/inputs/TransferSelector.vue";
import { STATES } from "@/utils/states";

export default {
    props: {
        interface: {
            type: Object,
            required: true
        },
        state: {
            type: Object,
            default: {}
        }
    },
    components: {
        TransferSelector,
        RegionSelector
    },
    setup: () => ({
        STATES
    }),
    mounted() {
        this.state.values = [];
        this.interface.flyout.setHeader('STATE SEARCHING');
    },
    methods: {
        onSetRegions(values) {
            this.state.values = values;
        }
    }
}
</script>